import { faFilter, faHourglassEnd, faPlay, faPlus, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ModalAddInfo from "./ModalAddInfo";
import { useGetTempMutation, useListQuery } from "../../../../api/tracker/taskR";
import List from "./List";
import ModalTaskR from "./ModalTaskR";
import { useListByStatusQuery } from "../../../../api/tracker/project";
import Filter from "../../../General/Filter";
import FilterProject from "../../../General/FilterProject";


function Index() {

    //TAB START
    const [tab1Show, setTab1Show] = useState(true);
    const [tab2Show, setTab2Show] = useState(false);
    const [tab3Show, setTab3Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
        }
    }
    //TAB END


    const [modalAddInfoShow, setModalAddInfoShow] = useState(false);
    const [modalTaskShow, setModalTaskShow] = useState(false);

    /******************************START ПОДГРУЗКА СПИСКОВ****************************/
    const listActiveApi = useListQuery({ ID_Status: 1 });
    const listArchiveApi = useListQuery({ ID_Status: 0 });
    const listFutureApi = useListQuery({ ID_Status: 2 });

    const [listActive, setListActive] = useState([]);
    const [listArchive, setListArchive] = useState([]);
    const [listFuture, setListFuture] = useState([]);

    const [filterlistProjectActive, setFilterlistProjectActive] = useState([]);
    const [filterlistProjectFuture, setFilterlistProjectFuture] = useState([]);
    //console.log(filterlistProjectActive)

    useEffect(() => {
        if (listActiveApi.status === 'fulfilled') {
            if (listActiveApi.data.Status == 1) {
                setListActive(listActiveApi.data.Data);

                //ФИЛЬТР
                var mass_1 = [];
                mass_1.push({ id: 0, name: 'Все проекты', count: listActiveApi.data.Data.length });
                listActiveApi.data.Data.map(item => {
                    if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                        mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listActiveApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                    }
                });
                setFilterlistProjectActive(mass_1);

                //Вызываем событие применения фильтра
                if (selectedFilterActive !== null) {
                    filterHandlerActive(selectedFilterActive)
                }
            }
        }
    }, [listActiveApi.status]);
    useEffect(() => {
        if (listArchiveApi.status === 'fulfilled') {
            if (listArchiveApi.data.Status == 1) {
                setListArchive(listArchiveApi.data.Data);
            }
        }
    }, [listArchiveApi.status]);
    useEffect(() => {
        if (listFutureApi.status === 'fulfilled') {
            if (listFutureApi.data.Status == 1) {
                setListFuture(listFutureApi.data.Data);

                var mass_1 = [];
                mass_1.push({ id: 0, name: 'Все проекты', count: listFutureApi.data.Data.length });
                listFutureApi.data.Data.map(item => {
                    if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                        mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listActiveApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                    }
                });
                setFilterlistProjectFuture(mass_1);
            }
        }
    }, [listFutureApi.status]);

    /*******************************END ПОДГРУЗКА СПИСКОВ*****************************/

    /******************************START ДОБАВЛЕНИЕ ЗАДАЧИ*****************************/
    const [getTemp, { data: getTempData, status: getTempStatus }] = useGetTempMutation();
    const [addModel, setAddModel] = useState({});

    //Выбор типа задачи
    function taskChangeHandler(id_type) {
        //setLoading(true);
        getTemp({ ID_Type: id_type });
    };

    //Обработка получения временной задачи
    useEffect(() => {
        if (getTempStatus === 'fulfilled') {
            if (getTempData.Status == 1) {
                setAddModel(getTempData.Data);
                setModalTaskShow(true);
            }
            else {
                //setLog(getTempData.Error);
            }
            //setLoading(false);
        }
        if (getTempStatus === 'rejected') {
            //setLoading(false);
            //setLog('Неизвестная ошибка!');
        }
    }, [getTempStatus]);
    /*******************************END ДОБАВЛЕНИЕ ЗАДАЧИ******************************/

    /*********************************START ФИЛЬТР***************************/

    const [selectedFilterActive, setSelectedFilterActive] = useState(null);

    function filterHandlerActive(id_project) {
        setSelectedFilterActive(id_project);
        if (id_project === 0) {
            setListActive(listActiveApi.data.Data)
        }
        else {
            setListActive(listActiveApi.data.Data.filter(a => a.ID_Project == id_project))
        }
    }
    /**********************************END ФИЛЬТР****************************/
    return (
        <>
            <ModalTaskR show={modalTaskShow} onHide={() => setModalTaskShow(false)} edit={false} model={addModel} listActive={listActive} listArchive={listArchive} listFuture={listFuture} />

            <div className="row">
                <div className="col-md-12">
                    <div className="main-card mb-3 card">
                        <div className="card-header-tab card-header-tab-animation card-header">
                            <div className="card-header-title">
                                <UncontrolledButtonDropdown>
                                    <Button onClick={(e) => setModalAddInfoShow(true)} color="secondary"><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавить задачу</Button>
                                    <DropdownToggle className="dropdown-toggle-split" caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => taskChangeHandler(1)}>Выполнят один из ответственных</DropdownItem>
                                        <DropdownItem onClick={() => taskChangeHandler(2)}>Выполняет каждый ответственный</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <ModalAddInfo show={modalAddInfoShow} onHide={() => setModalAddInfoShow(false)} />
                            </div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a
                                        id="tab_1"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab1Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faPlay} className="mr-5" /> Активные
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_2"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab2Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faRocket} className="mr-5" /> Будущие
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_3"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab3Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faHourglassEnd} className="mr-5" />  Завершенные
                                    </a>
                                </li>                               
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="mt-15 mb-15">
                                        <FilterProject list={filterlistProjectActive} clickHandler={filterHandlerActive} selected={selectedFilterActive} />
                                    </div>
                                    <div className="">                                        
                                        <List list={listActive} />
                                    </div>
                                </div>
                                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="mt-15 mb-15">
                                        <FilterProject list={filterlistProjectFuture} />
                                    </div>
                                    <div className="">
                                        <List list={listFuture} />
                                    </div>
                                </div>
                                <div className={tab3Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="">
                                        <List list={listArchive} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
