import { faCalendar, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer, useState } from 'react';
import ModalTask from './ModalTask';
import StringToDateTime from '../../../util/StringToDateTime';
import StringToDate from '../../../util/StringToDate';
import { useLazyGetQuery, useLazyParameterListQuery, useLazyUserListQuery } from '../../../api/tracker/task';


//Отобразит Дату начала/завершения задачи
function ShowDateStartEnd({ date_start, date_end }) {

    if (date_start !== null & date_end !== null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />{StringToDate(date_start)} - {StringToDate(date_end)}</>)
    }
    else if (date_start !== null & date_end === null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />С {StringToDate(date_start)}</>)
    }
    else if (date_start === null & date_end !== null) {
        return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' /> До {StringToDate(date_end)}</>)
    }
    else {
        return null;
    }
}

function List({ list, isLoading, filterCompleteDateStart, filterCompleteDateEnd, listCompleteApi }) {

    /*useEffect(() => {
        listDispatch({ type: 'load', data: list });
    }, [list]);*/

    const [listState, listDispatch] = useReducer(listReducer, { data: [] });
    function listReducer(state, action) {
        switch (action.type) {
            case 'load': {
                const newState = action.data.map(obj => {
                    return { ...obj, loading: false, error: null };
                })
                return { data: newState }
            }
            case 'loadingSet': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, loading: action.val };
                    }
                    else {
                        return { ...obj }
                    }
                })
                return { data: newState }
            }
            case 'errorSet': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, error: action.val };
                    }
                    else {
                        return { ...obj }
                    }
                })
                return { data: newState }
            }
        }
    }

    /******************************START МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЕ************************************/
    const [modalTaskShow, setModalTaskShow] = useState(false);

    const [getApi, getApiResult] = useLazyGetQuery();
    const [listParameterApi, listParameterApiResult] = useLazyParameterListQuery();
    const [listUserApi, listUserApiResult] = useLazyUserListQuery();

    //const [model, setModel] = useState(null);
    const [listParameter, setListParameter] = useState([]);
    const [listUser, setListUser] = useState([]);

    function modalEditHandler(id) {
        //listDispatch({ type: 'loadingSet', id: id, val: true });
        getApi({ ID: id });
    }

    //Подгружаем основную модель
    useEffect(() => {
        if (getApiResult.status === 'fulfilled') {
            if (getApiResult.data.Status === 1) {                
                //setModel(getApiResult.data.Data);
                setModalTaskShow(true);
                //listDispatch({ type: 'loadingSet', id: getApiResult.data.Data.ID, val: false });
                //listParameterApi({ ID_Task: getApiResult.originalArgs.ID });
            }
            else {

            }
        }
        if (getApiResult.status === 'rejected') {

        }
    }, [getApiResult.status]);

    //Подгружаем параметры
    useEffect(() => {
        if (listParameterApiResult.status === 'fulfilled') {
            if (listParameterApiResult.data.Status === 1) {               
                // setListParameter(listParameterApiResult.data.Data)
                // listUserApi({ ID_Task: getApiResult.originalArgs.ID })
            }
            else {

            }
        }
        if (listParameterApiResult.status === 'rejected') {

        }
    }, [listParameterApiResult.status]);

    //Подгружаем пользователей
    useEffect(() => {
        if (listUserApiResult.status === 'fulfilled') {
            if (listUserApiResult.data.Status === 1) {                
                //setListUser(listUserApiResult.data.Data);

                if (modalTaskShow === false) {
                    setModalTaskShow(true);
                }

                // listDispatch({ type: 'loadingSet', id: getApiResult.data.Data.ID, val: false });
            }
            else {

            }
        }
        if (listUserApiResult.status === 'rejected') {

        }
    }, [listUserApiResult.status]);
    /*******************************END МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЕ*************************************/

    console.log(modalTaskShow)

    return (
        <>
            {
                /*   modalTaskShow && (<ModalTask show={modalTaskShow} onHideTask={() => setModalTaskShow(false)} edit={true} model={model} listCompleteApi={listCompleteApi} listParameter={listParameter} listUser={listUser} listUserApi={listUserApi} />)*/
                modalTaskShow && (<ModalTask key={Math.random()} show={modalTaskShow} onHideTask={() => setModalTaskShow(false)} edit={true} model={getApiResult.data.Data} />)
            }


            {isLoading === true ? (
                <div>
                    <FontAwesomeIcon icon={faSpinner} size="2x" />
                </div>
            ) : (
                <div>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                    {list.map((model) => (
                                        <tr className='row_select' key={Math.random()}>
                                        <td width={50}>
                                            {model.ID_Priority === 1 && (<span className="badge badge-primary badge-dot badge-dot-lg"> </span>)}
                                            {model.ID_Priority === 2 && (<span className="badge badge-secondary badge-dot badge-dot-lg"> </span>)}
                                            {model.ID_Priority === 3 && (<span className="badge badge-danger badge-dot badge-dot-lg"> </span>)}
                                        </td>
                                        <td><div className='task_list_name'>{'#' + model.ID + ' ' + model.Name}</div>
                                            {model.Name_Project}<br></br>
                                            <small>{StringToDateTime(model.Date_Add)}</small>
                                        </td>
                                        <td>
                                            <ShowDateStartEnd date_start={model.Date_Start} date_end={model.Date_End} />
                                        </td>
                                        <td></td>
                                        <td className='text-end'>
                                                <button
                                                    className="btn-square btn-hover-shine btn btn-secondary"
                                                    onClick={() => modalEditHandler(model.ID)}
                                                    disabled={model.loading}
                                                >
                                                    {model.loading === false ?
                                                        (<FontAwesomeIcon icon={faEdit} />)
                                                        :
                                                        (<>...</>)
                                                    }
                                                <i>

                                                    </i>                                                  
                                            </button>
                                                {model.error !== null && (
                                                    <div className="form_error">{model.error}</div>
                                                )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    </div >
            )
            }
        </>
    );
}

export default List;