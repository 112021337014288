import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useReducer, useState } from "react";
import { useListActiveQuery, useListCompletedQuery, useListDraftQuery, useListFutureQuery, useParameterSetMassMutation, useProjectSetMutation, useProjectSetPrepareParameterMutation, useProjectSetPrepareUserMutation, useUpdateMutation } from "../../../api/tracker/task";
import { faEye, faFlagCheckered, faFloppyDisk, faFolder, faHouse, faPlay, faPlus, faRotateLeft, faUpload, faUserCheck, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormToJson from "../../../util/FormToJson";
import { convertToHTML } from 'draft-convert';
import { useListByUserMutation } from "../../../api/tracker/project";
import SelectSearch from "../../../components/ui/SelectSearch";
import htmlToDraft from "html-to-draftjs";
import ModalTaskUser from "./ModalTaskUser";
import ModalTaskComplete from "./ModalTaskComplete";
import StringToDateTime from "../../../util/StringToDateTime";
import ModalTaskChangeProject from "./ModalTaskChangeProject";
import Parameters from "../../General/Parameters";
import StringToInputDate from "../../../util/StringToInputDate";
import FileUploadModal from "../../General/FileUploadModal";
import FileList from "../../General/FileList";
import ModalTaskCompleteCancel from "./ModalTaskCompleteCancel";

function ModalTask(props) {
    const { show, onHideTask, edit, model, filterCompleteDateStart, filterCompleteDateEnd/*listCompleteApi, listParameter, listUserApi, listUser*/ } = props;

    const [listParameter, setListParameter] = useState([]);
    const [listUser, setListUser] = useState([]);

    //API
    const [update] = useUpdateMutation();
    const [parameterSetMass] = useParameterSetMassMutation();

    //API СПИСОК ЗАДАЧ
    const listDraftApi = useListDraftQuery();
    const listActiveApi = useListActiveQuery();
    const listFutureApi = useListFutureQuery();
    const listCompleteApi = useListCompletedQuery();

    //ПЕРЕМЕННЫЕ
    const [formLog, setFormLog] = useState(null);//???

    const [error, setError] = useState(null);
    const [isComplete, setIsComplete] = useState(null);
    const [loading, setLoading] = useState(false);

    //МОДАЛЬНЫЕ ОКНА
    const [modalFilesAddShow, setModalFilesAddShow] = useState(false);
    const [modalUserShow, setModalUserShow] = useState(false);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {

            setInputStatus(model.ID_Status);
            setInputDateStart(model.Date_Start);
            setInputDateEnd(model.Date_End);

            //Заполняем Description
            if (model.Description != null) {
                setInputDescription(model.Description);
                const blocksFromHtml = htmlToDraft(model.Description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                setEditorState(() => EditorState.createWithContent(contentState));
            }

            //Загружаем проекты
            listProject();

            //Информация о завершении задачи
            if (model.Complete_User === true || model.ID_Status === 2) {
                setIsComplete(true);
            }
            else {
                setIsComplete(false);
            }
            parameterDispatch({ type: 'load', data: listParameter })
        }
        //Действия при закрытии модального окна
        if (show == false) {
            //Очищаем поля       
            setInputProjectSelect(null);
            setInputProjectLog(null);          
            setEditorState(() => EditorState.createEmpty());
            listFileDispatch({ type: 'clear' });
        }
    }, [show]);

    //TAB
    const [tab1Show, setTab1Show] = useState(true);
    const [tab1Count, setTab1Count] = useState(0);
    const [tab2Show, setTab2Show] = useState(false);
    const [tab3Show, setTab3Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
        }
    }

    //ПЕРЕМЕННЫЕ ДЛЯ ФОРМЫ
    const [inputNameLog, setInputNameLog] = useState(null);
    const [inputDescription, setInputDescription] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const [inputPriority, setInputPriority] = useState(2);
    const [inputStatus, setInputStatus] = useState(null);

    const [inputDateStart, setInputDateStart] = useState(null);
    const [inputDateEnd, setInputDateEnd] = useState(null);
    const [inputDateLog, setInputDateLog] = useState(null);


    /*------------------------------START ПОДГРУЗКА ПАРАМЕТРОВ--------------------------*/
    const [parameterState, parameterDispatch] = useReducer(referenceReducer, { data: [] });
    function referenceReducer(state, action) {

        switch (action.type) {
            case 'load': {
                //Добавляем необходимые поля
                const newState = action.data.map(obj => {
                    return { ...obj, Error: false, ID_Task: model.ID };
                })
                return { data: newState }
            }
            case 'set': {
                /*
                ID	Name
                1	Логический
                2	Число
                3	Строка
                4	Справочник
                5	Дата
                */
                var val_bool = null;
                var val_float = null;
                var val_string = null;
                var val_date = null;

                if (action.id_type === 1) {
                    val_bool = action.value_bool;
                }
                if (action.id_type === 2) {
                    val_float = action.value_float;
                }
                if (action.id_type === 3) {
                    val_string = action.value_string;
                }
                if (action.id_type === 4) {
                    val_float = action.value_float;
                }
                if (action.id_type === 5) {
                    val_date = action.value_date;
                }

                if (val_string === '') {
                    val_string = null;
                }

                const newState = state.data.map(obj => {
                    if (obj.ID_Parameter === action.id_parameter) {
                        return { ...obj, Value_Bool: val_bool, Value_Float: val_float, Value_String: val_string, Value_Date: val_date };
                    }
                    return obj;
                })
                return { data: newState }
            }
        }
    }
    /*-------------------------------END ПОДГРУЗКА ПАРАМЕТРОВ---------------------------*/

    //Событие изменения описания
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setInputDescription(html);
    }, [editorState]);

    //КЛИК ПО ПРИОРИТЕТУ
    function clickPriority(arg) {
        setInputPriority(arg);
    }

    //ОТПРАВКА ФОРМА
    const submit = (e) => {
        e.preventDefault();

        //console.log('222')
        setLoading(true);

        setInputNameLog(null);
        setInputProjectLog(null);

        let valid = true;

        var jsonForm = FormToJson(e.target);
        jsonForm['ID'] = model.ID;
        jsonForm['Description'] = inputDescription;
        jsonForm['ID_Priority'] = inputPriority;
        jsonForm['ID_Status'] = inputStatus;
        jsonForm['Date_Start'] = inputDateStart === '' ? null : inputDateStart;
        jsonForm['Date_End'] = inputDateEnd === '' ? null : inputDateEnd;

        if (jsonForm.Name === null || jsonForm.Name === '' || jsonForm.Name === ' ') {
            setInputNameLog('Введите название задачи');
            valid = false;
            setLoading(false);           
        }
        if (jsonForm.ID_Project === null) {
            setInputProjectLog('Выберите проект');
            valid = false;
            setLoading(false);  
        }
        if (jsonForm.Date_Start > jsonForm.Date_End) {
            setInputDateLog('Дата завершения должна быть больше даты начала');
            valid = false;
            setLoading(false);  
        }
        if (valid === true) {
            update(jsonForm)
                .unwrap()
                .then((res1) => {
                    if (res1.Status !== 1) {
                        setError(res1.Error);
                        setLoading(false);
                    }
                    else {
                        parameterSetMass(parameterState.data)
                            .unwrap()
                            .then((res2) => {
                                if (res2.Status !== 1) {
                                    setLoading(false);
                                    setError(res1.Error);
                                }
                                else {
                                    setLoading(false);
                                    onHideTask(false);
                                    listDraftApi.refetch();
                                    listActiveApi.refetch();
                                    listFutureApi.refetch();
                                    //listCompleteApi.refetch();
                                }
                            })
                            .catch(() => {
                                setLoading(false);
                                setError('Неизвестная ошибка!');
                            })
                    }
                })
                .catch(() => {
                    setLoading(false);
                    setError('Неизвестная ошибка!');
                })
                ;
        }
    };   

    /*-------------------------------START ПРОЕКТЫ---------------------------*/
    //Модальные окна
    const [modalChangeProjectShow, setModalChangeProjectShow] = useState(false);

    //Api
    const [listProject, { data: listProjectData, status: listProjectStatus, isLoading: listProjectIsLoading }] = useListByUserMutation();
    const [projectSetPrepareUser, { data: projectSetPrepareUserData, status: projectSetPrepareUserStatus, isLoading: projectSetPrepareUserIsLoading }] = useProjectSetPrepareUserMutation();
    const [projectSetPrepareParameter, { data: projectSetPrepareParameterData, status: projectSetPrepareParameterStatus, isLoading: projectSetPrepareParameterIsLoading }] = useProjectSetPrepareParameterMutation();
    const [projectSet, { data: projectSetData, status: projectSetStatus, isLoading: projectSetIsLoading }] = useProjectSetMutation();

    //Переменные 
    const [idProjectNew, setIdProjectNew] = useState([]);
    const [modelProjectSetPrepareUser, setModelProjectSetPrepareUser] = useState([]);
    const [modelProjectSetPrepareParameter, setModelProjectSetPrepareParameter] = useState([]);

    const [inputProject, setInputProject] = useState([]);
    const [inputProjectLog, setInputProjectLog] = useState([]);
    const [inputProjectSelect, setInputProjectSelect] = useState(null);

    //Подгрузка проектов
    useEffect(() => {
        if (listProjectStatus === 'fulfilled') {

            const jsonForm = [];

            for (let i = 0; i < listProjectData.Data.length; i++) {
                jsonForm.push(
                    {
                        value: listProjectData.Data[i].ID,
                        label: listProjectData.Data[i].Name_Full,
                        use_tasks: listProjectData.Data[i].Use_Tasks
                    })
            }
            setInputProject(jsonForm.filter(a => a.use_tasks === true));
            setInputProjectSelect(jsonForm.find(a => a.value == model.ID_Project));
        }
    }, [listProjectStatus]);

    //Изменение проекта
    function changeProject(e) {
        setInputProjectLog(null);
        // setLoadingProject(true);
        setIdProjectNew(e.value);
        projectSetPrepareUser({ ID_Task: model.ID, ID_Project_New: e.value });
        setInputProjectSelect(e)
    }

    //Шаг 1. Проверка пользователей на удаление
    useEffect(() => {
        if (projectSetPrepareUserStatus === 'fulfilled') {
            if (projectSetPrepareUserData.Status == 1) {
                //Переходим к шагу №2
                projectSetPrepareParameter({ ID_Task: model.ID, ID_Project_New: idProjectNew });
            }
            else {
                setInputProjectLog(projectSetPrepareUserData.Error);
                // setLoadingProject(false);
            }

        }
        if (projectSetPrepareUserStatus === 'rejected') {
            setInputProjectLog('Неизвестная ошибка!');
            // setLoadingProject(false);
        }
    }, [projectSetPrepareUserStatus]);

    //Шаг 2. Проверка параметров на удаление
    useEffect(() => {
        if (projectSetPrepareParameterStatus === 'fulfilled') {
            if (projectSetPrepareParameterData.Status == 1) {
                //Если есть пользователи/параметры, которые будут удалены, то показываем модальное окно с предупреждением
                if (projectSetPrepareParameterData.Data.length > 0 || projectSetPrepareUserData.Data.length > 0) {
                    setModalChangeProjectShow(true);
                    setModelProjectSetPrepareUser(projectSetPrepareUserData.Data);
                    setModelProjectSetPrepareParameter(projectSetPrepareParameterData.Data);
                    //   setLoadingProject(false);
                }
                else {
                    projectSet({ ID_Task: model.ID, ID_Project: inputProjectSelect.value });
                }
            }
            else {
                setInputProjectLog(projectSetPrepareParameterData.Error);
                // setLoadingProject(false);
            }          
        }
        if (projectSetPrepareParameterStatus === 'rejected') {

            setInputProjectLog('Неизвестная ошибка!');
            // setLoadingProject(false);
        }
    }, [projectSetPrepareParameterStatus]);

    //Подтверждение изменения проекта
    useEffect(() => {
        if (projectSetStatus === 'fulfilled') {
            if (projectSetData.Status == 1) {

            }
            else {
                setInputProjectLog(projectSetData.Error);             
            }
        }
        if (projectSetStatus === 'rejected') {
            setInputProjectLog('Неизвестная ошибка!');            
        }      
    }, [projectSetStatus]);

    /*--------------------------------END ПРОЕКТЫ----------------------------*/
    /*--------------------------------START ФАЙЛЫ----------------------------*/
    const [listFileState, listFileDispatch] = useReducer(listFileReducer, { data: [] });
    function listFileReducer(state, action) {
        switch (action.type) {
            case 'load': {
                setTab1Count(action.data.length);
                //Добавляем необходимые поля
                const newState = action.data.map(obj => {
                    return { ...obj, download_loading: false, download_error: false };
                })
                return { data: newState }
            }
            case 'download_loading': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, download_loading: action.loading };
                    }
                    else {
                        return { ...obj }
                    }
                })

                return { data: newState }
            }
            case 'download_error': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, download_error: action.val };
                    }
                    else {
                        return { ...obj }
                    }
                })

                return { data: newState }
            }
            case 'remove': {
                setTab1Count(tab1Count - 1)
                return { data: state.data.filter(a => a.ID !== action.id) }
            }
            case 'edit': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return {
                            ...obj
                            , Name: action.name
                            , Name_Full: action.name + obj.Extension
                        };
                    }
                    else {
                        return { ...obj }
                    }
                })

                return { data: newState }
            }
            case 'clear': {
                setTab1Count(0)
                return { data: [] }
            }
        }
    }
    /*---------------------------------END ФАЙЛЫ-----------------------------*/

    const [modalCompleteShow, setModalCompleteShow] = useState(false);
    const [modalCompleteCancelShow, setModalCompleteCancelShow] = useState(false);

    return (
        <>
            {model !== undefined && (
                <Modal show={show} onHide={onHideTask} dialogClassName="modal-80">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <>
                                <i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i> {edit ? ('Изменение задачи' + ' #' + model.ID) : 'Добавление задачи'}
                            </>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row" >
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-card card">
                                            <div className="card-header-tab card-header-tab-animation card-header">
                                                <div className="card-header-title">
                                                    {model.Name_Status}
                                                </div>
                                                <ul className="nav">
                                                    <li className="nav-item">
                                                        <a
                                                            id="tab_1"
                                                            onClick={tabHandleClick}
                                                            data-toggle="tab"
                                                            className={tab1Show ? "nav-link active show" : "nav-link show"}
                                                        >
                                                            <FontAwesomeIcon icon={faHouse} /> Основное
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            id="tab_2"
                                                            onClick={tabHandleClick}
                                                            data-toggle="tab"
                                                            className={tab2Show ? "nav-link active show" : "nav-link show"}
                                                        >
                                                            <FontAwesomeIcon icon={faFolder} /> Файлы {tab1Count !== 0 && ('(' + tab1Count + ')')}
                                                        </a>
                                                    </li>                                                 
                                                </ul>
                                            </div>
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="card-block-title">Основное</div>
                                                                <Form id="my-form" onSubmit={submit}>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                        <Form.Label>Название задачи</Form.Label>
                                                                        <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' disabled={loading} defaultValue={model.Name} />
                                                                        {inputNameLog !== null && <div className="form_error">{inputNameLog}</div>}
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                                                        <Form.Label>Проект</Form.Label>
                                                                        <SelectSearch name="ID_Project" placeholder='Выберите проект' className="z-index-max" disabled={loading} options={inputProject} defaultValue={inputProjectSelect} onChange={(e) => changeProject(e)} />
                                                                        {inputProjectLog !== null && <div className="form_error">{inputProjectLog}</div>}
                                                                        {loading === true && (
                                                                            <div className="loader_01"></div>
                                                                        )}
                                                                        <ModalTaskChangeProject
                                                                            show={modalChangeProjectShow}
                                                                            onHide={() => setModalChangeProjectShow(false)}
                                                                            id_task={model.ID}
                                                                            id_project={inputProjectSelect}
                                                                            modelProjectSetPrepareUser={modelProjectSetPrepareUser}
                                                                            modelProjectSetPrepareParameter={modelProjectSetPrepareParameter}
                                                                            projectSet={projectSet}
                                                                            projectSetData={projectSetData}
                                                                            projectSetStatus={projectSetStatus}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Label>Описание</Form.Label>
                                                                        <Editor
                                                                            editorState={editorState}
                                                                            onEditorStateChange={setEditorState}
                                                                            disabled={loading}
                                                                            editorClassName={loading && 'editor-class-disabled'}
                                                                        />
                                                                    </Form.Group>
                                                                </Form>                                                               
                                                                <Parameters list={parameterState.data.filter(a => a.ID_Display === 1)} dispatch={parameterDispatch} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="card-block-title">Приоритет</div>
                                                                <div className="text-center mt-15">
                                                                    <button onClick={() => clickPriority(1)} className={inputPriority === 1 ? 'btn-pill btn btn-primary' : 'btn-pill btn btn-outline-primary'}>Низкий</button>
                                                                    <button onClick={() => clickPriority(2)} className={inputPriority === 2 ? 'ml-15 btn-pill btn btn-secondary' : 'ml-15 btn-pill btn btn-outline-secondary'}>Нормальный</button>
                                                                    <button onClick={() => clickPriority(3)} className={inputPriority === 3 ? 'ml-15 btn-pill btn btn-danger' : 'ml-15 btn-pill btn btn-outline-danger'}>Высокий</button>
                                                                </div>
                                                                <div className="mt-20">                                                                    
                                                                    <Parameters list={parameterState.data.filter(a => a.ID_Display === 2)} dispatch={parameterDispatch} />                                                                    
                                                                </div>                                                                
                                                                <div className="card-block-title">Даты</div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-3" key={model.ID}>
                                                                            <Form.Label>Дата начала</Form.Label>
                                                                            <Form.Control name="Date_Start" type="date" defaultValue={StringToInputDate(model.Date_Start)} onChange={(e) => setInputDateStart(e.target.value)} />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-3" key={model.ID}>
                                                                            <Form.Label>Дата завершения</Form.Label>
                                                                            <Form.Control name="Date_End" type="date" defaultValue={StringToInputDate(model.Date_End)} onChange={(e) => setInputDateEnd(e.target.value)} />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                                {inputDateLog !== null && <div className="form_error">{inputDateLog}</div>}
                                                                <div className="mt-20">                                                                    
                                                                    <Parameters list={parameterState.data.filter(a => a.ID_Display === 5)} dispatch={parameterDispatch} />                                                                    
                                                                </div>
                                                                <div className="card-block-title">Пользователи</div>
                                                                <div className="text-end mt-15">
                                                                    <div>
                                                                        <button onClick={() => setModalUserShow(true)} className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary"><FontAwesomeIcon icon={faUserPen} /> Изменить...</button>
                                                                        <ModalTaskUser show={modalUserShow} onHide={() => setModalUserShow(false)} id_task={model.ID} /*listUserApi={listUserApi}*/ />
                                                                    </div>
                                                                </div>
                                                                <div className="div">
                                                                    <div className="scrollbar-container ps ps--active-y">
                                                                        <div className="p-2">
                                                                            {listUser !== undefined && listUser.map((model) => (
                                                                                    <div key={model.ID_User} className="row row_select task_user_row">
                                                                                        <div className="col-md-12">
                                                                                            {model.ID_Action === 1 && (
                                                                                                <FontAwesomeIcon fontSize={'20px'} icon={faEye} className="mr-10 float-start text-warning" />
                                                                                            )}
                                                                                            {model.ID_Action === 2 && (
                                                                                                <FontAwesomeIcon fontSize={'20px'} icon={faUserCheck} className="mr-10 float-start text-success" />
                                                                                            )}

                                                                                            {model.Complete === true ? (<del>{model.Name_User}</del>) : (<div>{model.Name_User}</div>)}
                                                                                            {model.Complete === true && (
                                                                                                <>
                                                                                                <div className="task_user_comment">{model.Complete_Comment}</div>
                                                                                                <div className="task_user_date">{StringToDateTime(model.Complete_Date)}</div>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-20">                                                                    
                                                                    <Parameters list={parameterState.data.filter(a => a.ID_Display === 3)} dispatch={parameterDispatch} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*reqStatus == 'rejected' && <div className="form_error">Неизвестная ошибка! Обратитесь в техническую поддержку</div>*/}
                                                    </div>
                                                    <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                        <div className="">
                                                            <button
                                                                className="btn-square btn-hover-shine btn btn-secondary mt-10"
                                                                type="submit"
                                                                onClick={() => setModalFilesAddShow(true)}
                                                            >
                                                                <FontAwesomeIcon icon={faUpload} className="mr-5" /> Добавить файлы
                                                            </button>
                                                            {/*<FileUploadModal show={modalFilesAddShow} onHide={() => setModalFilesAddShow(false)} type={1} data={model.ID} listApi={listFile} />*/} 
                                                        </div>
                                                        <div className="mt-20">
                                                            <FileList Type={1} ID_Object={model.ID} listDispatch={listFileDispatch} listState={listFileState} />
                                                        </div>
                                                    </div>                                                   
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-12"></div>
                                                </div>
                                            </div>
                                            <div className="card-footer  d-block">
                                                <div><small><u>Владелец:</u> {model.User_Add_Name}</small></div>
                                                <div>
                                                    <small>
                                                        <u>Тип задачи:</u>
                                                        {model.ID_Type === 1 && ' Выполнят один из ответственных'}
                                                        {model.ID_Type === 2 && ' Выполняет каждый ответственный'}
                                                        {model.ID_Type === 3 && ' Ответственные указаны в чек листе'}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-card mb-3 card">
                                            <div className="card-header-tab card-header-tab-animation card-header">

                                            </div>
                                            <div className="card-body">
                                                <div className="card-block-title">Действия</div>
                                                {isComplete === true ?
                                                    (
                                                        <>
                                                            <button className="btn-square btn-hover-shine btn btn-width-100 mt-10 btn-light"
                                                                type="button"                                                                
                                                                disabled={loading}
                                                                onClick={() => setModalCompleteCancelShow(true)}
                                                            >                                                               
                                                                <FontAwesomeIcon icon={faRotateLeft} className="mr-5 " /> Отменить                                                                  
                                                            </button>
                                                            <ModalTaskCompleteCancel show={modalCompleteCancelShow} onHide={() => setModalCompleteCancelShow(false)} onHideTask={onHideTask} id_task={model.ID} /*listCompleteApi={listCompleteApi}*/ filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            {model.ID_Status === -1 &&
                                                    (<>
                                                        <button
                                                            className="btn-square btn-hover-shine btn btn-secondary btn-width-100 mt-10"
                                                            form="my-form"
                                                                    type="submit"                                                            
                                                                    disabled={loading}                                                       >
                                                                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить как черновик
                                                        </button>
                                                        <button
                                                            className="btn-square btn-hover-shine btn btn-width-100 mt-10 btn-green"
                                                                    form="my-form" type="submit" disabled={loading}                                                        >
                                                                    <FontAwesomeIcon icon={faPlay} className="mr-5 " /> В работу
                                                        </button>
                                                    </>
                                                    )
                                                }
                                                {model.ID_Status === 0 &&
                                                    (<>
                                                        <button
                                                            className="btn-square btn-hover-shine btn btn-secondary btn-width-100 mt-10"
                                                                    form="my-form" type="submit" disabled={loading}
                                                                >
                                                                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить

                                                        </button>
                                                        <button
                                                            className="btn-square btn-hover-shine btn btn-width-100 mt-10 btn-green"
                                                                    form="my-form" type="submit" disabled={loading}                                                        >
                                                                    <FontAwesomeIcon icon={faPlay} className="mr-5 " /> В работу
                                                        </button>
                                                    </>
                                                    )
                                                }
                                                {model.ID_Status === 1 &&
                                                    (<>
                                                        <button
                                                            className="btn-square btn-hover-shine btn btn-secondary btn-width-100 mt-10"
                                                            form="my-form"
                                                            type="submit"
                                                                    disabled={loading}
                                                                >
                                                                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить
                                                        </button>
                                                                <button className="btn-square btn-hover-shine btn btn-width-100 mt-10 btn-yellow"                                                        
                                                        type="button"
                                                        onClick={() => setModalCompleteShow(true)}
                                                                    disabled={loading}
                                                                >                                                                 
                                                                    <FontAwesomeIcon icon={faFlagCheckered} className="mr-5 " /> Завершить

                                                        </button>
                                                                <ModalTaskComplete show={modalCompleteShow} onHide={() => setModalCompleteShow(false)} onHideTask={onHideTask} id_task={model.ID} /*listCompleteApi={listCompleteApi}*/ filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />

                                                    </>
                                                    )
                                                }
                                                        </>

                                                    )
                                                }

                                                {loading === true && (<span className="loader_01"></span>)}

                                                {formLog !== null && <div className="form_error text-center mt-15">{formLog}</div>}
                                                <div className="mt-20">                                                    
                                                    <Parameters list={parameterState.data.filter(a => a.ID_Display === 4)} dispatch={parameterDispatch} />                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            )}

        </>
    );
}
export default ModalTask;
