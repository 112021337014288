import { faDownload, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import FileListModalRemove from './FileListModalRemove';
import StringToDateTime from '../../util/StringToDateTime';
import FileListModalEdit from './FileListModalEdit';
import axios from 'axios';

import Img_Type_0 from '../General/Files/File_Icon/Type_0.svg';
import Img_Type_4 from '../General/Files/File_Icon/Type_4.svg';
import Img_Type_5 from '../General/Files/File_Icon/Type_5.svg';
import Img_Type_7 from '../General/Files/File_Icon/Type_7.svg';

export function FileListIcon({ id_type }) {
    /*
    ID	Name
0	Не определено
1	Видео
2	Фото
3	Музыка
4	Excel
5	Word
6	Pdf
7	Архив
    */

    if (id_type === 4) {
        return <img src={Img_Type_4} style={{ width: '48px' }} />
    }
    if (id_type === 5) {
        return <img src={Img_Type_5} style={{ width: '48px' }} />
    }
    if (id_type === 7) {
        return <img src={Img_Type_7} style={{ width: '48px' }} />
    }

    return <img src={Img_Type_0} style={{ width: '48px' }} />
}

function FileList({ Type, listDispatch, listState }) {
    //Type: 1-Задача; 2-Регулярная задача

    /****************START СКАЧИВАНИЕ ФАЙЛА******************/
    function downloadHandler(e, model) {
        listDispatch({ type: 'download_loading', loading: true, id: model.ID });

        if (Type === 1) {
            axios({
                url: '/api_main/tracker/task/fileDownload?ID=' + model.ID, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', model.Name_Full); //or any other extension
                document.body.appendChild(link);
                link.click();
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).then(() => {
                listDispatch({ type: 'download_loading', loading: false, id: model.ID });
            }).catch((e) => {
                listDispatch({ type: 'download_loading', loading: false, id: model.ID });
                listDispatch({ type: 'download_error', val: true, id: model.ID });         
            });
        }
        if (Type === 2) {
            axios({
                url: '/api_main/tracker/taskR/fileDownload?ID=' + model.ID, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                //console.log(response)

                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', model.Name_Full); //or any other extension
                document.body.appendChild(link);
                link.click();
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).then((e) => {
                listDispatch({ type: 'download_loading', loading: false, id: model.ID });
            }).catch((e) => {
                listDispatch({ type: 'download_loading', loading: false, id: model.ID });
                listDispatch({ type: 'download_error', val: true, id: model.ID });
            });
        }

    }
    /*****************END СКАЧИВАНИЕ ФАЙЛА*******************/

    /*****************START МОДАЛЬНЫЕ ОКНА*******************/
    const [modalRemove, setModalRemove] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modelSelected, setModelSelected] = useState(null);

    function modalRemoveShowHandler(e, model) {
        e.preventDefault();
        setModelSelected(model);
        setModalRemove(true);
    }
    function modalEditShowHandler(e, model) {
        e.preventDefault();
        setModelSelected(model);
        setModalEdit(true);
    }
    /******************END МОДАЛЬНЫЕ ОКНА*******************/


    return (
        <>
            <FileListModalRemove show={modalRemove} onHide={() => setModalRemove(false)} model={modelSelected} listDispatch={listDispatch} type={Type} />
            <FileListModalEdit show={modalEdit} onHide={() => setModalEdit(false)} model={modelSelected} listDispatch={listDispatch} type={Type} />

                <div>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                    {listState.data.map((model) => (
                                    <tr className='row_select' key={model.ID}>
                                            <td width={30}>
                                                {FileListIcon({ id_type: model.ID_Type })}
                                            </td>
                                            <td>
                                                <div className='task_list_name1'>{model.Name}</div>
                                                <small>{StringToDateTime(model.Date_Add)}</small>
                                        </td>
                                        <td className='text-end'>
                                                <button
                                                    disabled={model.download_loading}
                                                    className="btn-square btn-hover-shine btn btn-secondary mr-10"
                                                    onClick={(e) => downloadHandler(e, model)} >
                                                    {model.download_loading === true ?
                                                        ('...')
                                                        :
                                                        (<i>
                                                            <FontAwesomeIcon icon={faDownload} />
                                                        </i>)
                                                    }
                                                </button>
                                                <button className="btn-square btn-hover-shine btn btn-secondary mr-10" onClick={(e) => modalEditShowHandler(e, model)}>
                                                    <i>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </i>
                                                </button>   
                                                <button className="btn-square btn-hover-shine btn btn-secondary" onClick={(e) => modalRemoveShowHandler(e, model)}>
                                                    <i>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </i>
                                                </button>   
                                                {model.download_error && (
                                                    <div className='widget-subheading text-danger'>Ошибка скачивания</div>
                                                )}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >

        </>
    );
}

export default FileList;