import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateForward, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useCompleteCancelMutation, useListActiveQuery, useListCompletedQuery, useListDraftQuery, useListFutureQuery } from "../../../api/tracker/task";
import { useEffect, useState } from "react";

function ModalTaskCompleteCancel(props) {
    const { show, onHide, onHideTask, id_task /*, listCompleteApi */ } = props;

    //Действия при открытии/закрытии модального окна
    useEffect(() => {
        //Действия при открытии модального окна
        if (show == true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setLog(null);
            setLoading(false);
        }
    }, [show]);



    //СПИСОК ЗАДАЧ
    const listActiveApi = useListActiveQuery();
    const listFutureApi = useListFutureQuery();
    const listCompleteApi = useListCompletedQuery();

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);

    const [complete, { data: completeData, status: completeStatus }] = useCompleteCancelMutation();

    useEffect(() => {
        if (completeStatus === 'fulfilled') {
            if (completeData.Status == 1) {
                listActiveApi.refetch();
                listFutureApi.refetch();
                listCompleteApi.refetch();


                onHide(); 
                onHideTask();

                setLoading(false);
            }
            else {
                setLog(completeData.Error);
                setLoading(false);
            }
        }
        if (completeStatus === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [completeStatus]);

    function completeHandler() {
        setLoading(true);
        complete({ ID_Task: id_task });
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faRotateForward} /></i> Отмена завершения задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Подтвердите отмену завершения задачи
                    {log !== null && <div className="form_error text-center mt-15">{log}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        onClick={onHide}
                    >
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-light"
                        disabled={loading}
                        onClick={() => completeHandler()}
                    >
                        {loading ? (
                            "Загрузка..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faRotateForward} className="mr-5" /> Отменить
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalTaskCompleteCancel;
