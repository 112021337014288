import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//https://habr.com/ru/articles/730916/
export const projectApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api_main/tracker/project/' }),
    tagTypes: ['projectList', 'userList'],
    reducerPath: 'ProjectApi',
    endpoints: (builder) => ({
        add: builder.mutation({
            query: (args) => {
                return {
                    url: 'add',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['projectList'],
        }),
        update: builder.mutation({
            query: (args) => {
                return {
                    url: 'update',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['projectList'],
        }),
        remove: builder.mutation({
            query: (args) => {
                return {
                    url: 'remove',
                    method: 'POST',
                    body: args,
                };
            },
            invalidatesTags: ['projectList'],
        }),
        list: builder.query({
            query: (args) => {
                return {
                    url: 'list',
                    method: 'GET',
                };
            },
            providesTags: ['projectList'],
        }),
        listByUser: builder.mutation({
            query: (args) => {
                return {
                    url: 'listByUser',
                    method: 'GET',
                };
            },       
        }),
        listByStatus: builder.query({
            query: (args) => {
                return {
                    url: 'listByStatus',
                    method: 'GET',
                    params: args,
                };
            },
            providesTags: ['projectList'],
        }),    
        listByUser_old: builder.query({
            query: (args) => {
                return {
                    url: 'listByUser',
                    method: 'GET',
                };
            },
            //providesTags: ['projectList'],
        }),
        useTask: builder.mutation({
            query: (args) => {
                return {
                    url: 'useTask',
                    method: 'POST',
                    body: args,
                };
            },
        }),
        useRtask: builder.mutation({
            query: (args) => {
                return {
                    url: 'useRtask',
                    method: 'POST',
                    body: args,
                };
            },
        }),
        useBoard: builder.mutation({
            query: (args) => {
                return {
                    url: 'useBoard',
                    method: 'POST',
                    body: args,
                };
            },
        }),
        useRboard: builder.mutation({
            query: (args) => {
                return {
                    url: 'useRboard',
                    method: 'POST',
                    body: args,
                };
            },
        }),

        //ПОЛЬЗОВАТЕЛИ ПРОЕКТА
        userSet: builder.mutation({
            query: (args) => {                
                return {
                    url: 'userSet',
                    method: 'POST',
                    body: args,
                };
            },

        }),
        userSetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'userSetMass',
                    method: 'POST',
                    body: args,
                };
            },

        }),
        userList: builder.mutation({
            query: (args) => {
                return {
                    url: 'userList',
                    method: 'GET',
                    params: args,
                };
            },
        }),

       /* userList: builder.query({
            query: (args) => {
                return {
                    url: 'userList',
                    method: 'GET',
                    params: args,
                };
            },
            providesTags: ['userList'],
        }),*/
    }),
});

export const { useAddMutation, useUpdateMutation, useRemoveMutation, useListQuery,
    useUserSetMutation, useUserSetMassMutation, useUserListMutation, useListByUserMutation, useListByStatusQuery,
    useUseTaskMutation, useUseRtaskMutation, useUseBoardMutation, useUseRboardMutation
} = projectApi;