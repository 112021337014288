import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faSave, faSpinner, faUsers, faXmark } from "@fortawesome/free-solid-svg-icons";

import { useLazyGetDetailQuery, useUserAvailableListQuery, useUserSetMassMutation } from "../../../api/tracker/task";
import { useEffect, useState } from "react";

function ModalTaskUser(props) {
    const { show, onHide, id_task, listUserApi } = props;

    //Действия при открытии/закрытии модального окна
    useEffect(() => {
        //Действия при открытии модального окна
        if (show == true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setFormLog(null);
        }
    }, [show]);

    const [formLog, setFormLog] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);

    const listUserAvailable = useUserAvailableListQuery({ ID_Task: id_task });
    const [setMass, { data: setMassData, status: setMassStatus }] = useUserSetMassMutation();
    //const [listUser, { data: listUserData, status: listUserStatus, isLoading: listUserIsLoading, }] = useUserListMutation();

    //Загрузка данных в состояние
    var [list, setList] = useState([]);
    useEffect(() => {
        if (listUserAvailable.status === 'fulfilled') {

            //Добавляем недостающие параметры (ID_Task)
            const newState = listUserAvailable.data.Data.map(obj => {
                return { ...obj, ID_Task: id_task, change: false };
            });

            setList(newState);
        }
    }, [listUserAvailable.status]);

    //Изменит состояние пользователя
    function setUser(e) {

        const id_user = Number(e.target.getAttribute('id_user'));
        var id_action = Number(e.target.getAttribute('id_action'));
        const id_action_model = Number(e.target.getAttribute('id_action_model'));

        const newState = list.map(obj => {
            if (obj.ID_User === id_user) {

                if (id_action === id_action_model) {
                    id_action = null;
                }

                return { ...obj, ID_Action: id_action, change: true };
            }
            return obj;
        });

        setList(newState);


    }

    //Сохранение изменений
    function saveChange(e) {
        setBtnLoading(true);
        //Отправляем на сервер только измененные строки
        setMass(list.filter(a => a.change == true));
    }

    //const [getDetail, getDetailResult] = useLazyGetDetailQuery();

    //ОБРАБОТКА ОТПРАВЛЕННОЙ ФОРМЫ
    useEffect(() => {
        if (setMassStatus === 'fulfilled') {
            setBtnLoading(false);
            if (setMassData.Status == 1) {
                onHide();
                listUserApi({ ID_Task: id_task });               
            }
            else {
                setFormLog(setMassData.Error)
            }
        }
        if (setMassStatus === 'rejected') {
            setBtnLoading(false);
            setFormLog('Неизвестная ошибка!')
        }
    }, [setMassStatus]);


    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faUsers} /></i> Выберите пользователей
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-area-lg">
                        <div className="scrollbar-container ps ps--active-y">
                            <div className="p-2">
                                <ul className="todo-list-wrapper list-group list-group-flush">
                                    {listUserAvailable.isLoading === true ? (
                                        <div>
                                            <FontAwesomeIcon icon={faSpinner} size="2x" />
                                        </div>
                                    ) : (
                                        list.map((model) => (
                                            <li className="list-group-item row_select" key={model.ID_User}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-2">

                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">{model.Name_User}
                                                                <div className="badge badge-danger ml-2"></div>
                                                            </div>
                                                            <div className="widget-subheading"><i>{model.Name_Role}</i></div>
                                                        </div>
                                                        <div className="widget-content-right">
                                                            {model.ID_Action === 1 &&
                                                                (
                                                                    <>
                                                                        <button className="mr-10 btn btn-sm btn-dashed btn-outline-secondary active" id_user={model.ID_User} id_action={1} id_action_model={model.ID_Action} onClick={(e) => setUser(e)}>
                                                                            <FontAwesomeIcon icon={faEye} className="mr-5" />
                                                                            Наблюдатель
                                                                        </button>
                                                                        <button className="btn btn-sm btn-dashed btn-outline-secondary" id_user={model.ID_User} id_action={2} id_action_model={model.ID_Action} onClick={(e) => setUser(e)}>
                                                                            <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                                                            Исполнитель
                                                                        </button>
                                                                    </>
                                                                )
                                                            }
                                                            {model.ID_Action === 2 &&
                                                                (


                                                                    <>
                                                                        <button className="mr-10 btn btn-sm btn-dashed btn-outline-secondary" id_user={model.ID_User} id_action={1} id_action_model={model.ID_Action} onClick={(e) => setUser(e)}>
                                                                            <FontAwesomeIcon icon={faEye} className="mr-5" />
                                                                            Наблюдатель
                                                                        </button>
                                                                        <button className="btn btn-sm btn-dashed btn-outline-secondary active" id_user={model.ID_User} id_action={2} id_action_model={model.ID_Action} onClick={(e) => setUser(e)}>
                                                                            <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                                                            Исполнитель
                                                                        </button>
                                                                    </>
                                                                )
                                                            }
                                                            {model.ID_Action === null &&
                                                                (
                                                                    <>
                                                                        <button className="mr-10 btn btn-sm btn-dashed btn-outline-secondary" id_user={model.ID_User} id_action={1} id_action_model={model.ID_Action} onClick={(e) => setUser(e)}>
                                                                            <FontAwesomeIcon icon={faEye} className="mr-5" />
                                                                            Наблюдатель
                                                                        </button>
                                                                        <button className="btn btn-sm btn-dashed btn-outline-secondary" id_user={model.ID_User} id_action={2} id_action_model={model.ID_Action} onClick={(e) => setUser(e)}>
                                                                            <FontAwesomeIcon icon={faCheck} className="mr-5" />
                                                                            Исполнитель
                                                                        </button>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {formLog !== null && <div className="form_error text-center mt-15">{formLog}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        onClick={onHide}
                    >
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        disabled={btnLoading}
                        onClick={() => saveChange()}
                    >
                        {btnLoading ? (
                            "Сохранение..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faSave} className="mr-5" /> Сохранить изменения
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalTaskUser;
