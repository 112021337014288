import { faArchive, faFlagCheckered, faInfo, faPlay, faPlus, faRocket, faSpinner, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import ModalAddInfo from "./ModalAddInfo";
import { useGetTempMutation, useListActiveQuery, useListCompletedQuery, useListDraftQuery, useListFutureQuery } from "../../../api/tracker/task";
import ModalTask from "./ModalTask";
import List from "./List";
import FilterProject from "../../General/FilterProject";
import { Form } from "react-bootstrap";
import StringToInputDate from "../../../util/StringToInputDate";

function Index() {

    //TAB START
    const [tab1Show, setTab1Show] = useState(false);
    const [tab2Show, setTab2Show] = useState(true);
    const [tab3Show, setTab3Show] = useState(false);
    const [tab4Show, setTab4Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
            setTab4Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
            setTab4Show(false);
        }
        if (e.currentTarget.id === "tab_4") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(true);
        }
    }
    //TAB END

    //START Модальные окна
    const [modalAddInfoShow, setModalAddInfoShow] = useState(false);
    const [modalTaskShow, setModalTaskShow] = useState(false);

    function modalInfoHandler() {
        setModalAddInfoShow(true);
    }
    //END Модальные окна


    //START ВЫБОР ТИПА ЗАДАЧИ
    const [getTemp, { data: getTempData, status: getTempStatus }] = useGetTempMutation();
    const [addModel, setAddModel] = useState({});

    const [logTaskChange, setLogTaskChange] = useState(null);
    const [loadingTaskChange, setLoadingTaskChange] = useState(false);

    function taskChangeHandler(arg) {
        setLoadingTaskChange(true);
        getTemp({ ID_Type: arg });
    };

    //Обработка получения временной задачи
    useEffect(() => {
        if (getTempStatus === 'fulfilled') {
            if (getTempData.Status == 1) {
                setAddModel(getTempData.Data);
                setModalTaskShow(true);
            }
            else {
                setLogTaskChange(getTempData.Error);
            }
            setLoadingTaskChange(false);
        }
        if (getTempStatus === 'rejected') {
            setLoadingTaskChange(false);
            setLogTaskChange('Неизвестная ошибка!');
        }
    }, [getTempStatus]);
    //END ВЫБОР ТИПА ЗАДАЧИ


    //СЧЕТЧИК ЗАДАЧ TAB
    const [tab1Count, setTab1Count] = useState(0);
    const [tab2Count, setTab2Count] = useState(0);
    const [tab3Count, setTab3Count] = useState(0);

    /****************************START API СПИСОК ЗАДАЧ**********************/

    const [filterCompleteDateStart, setFilterCompleteDateStart] = useState();
    const [filterCompleteDateEnd, setFilterCompleteDateEnd] = useState();
    const [filterCompleteError, setFilterCompleteError] = useState(null);
    const [filterCompleteLoading, setFilterCompleteLoading] = useState(false);

    const listCompleteApi = useListCompletedQuery();
    const listDraftApi = useListDraftQuery();
    const listActiveApi = useListActiveQuery();
    const listFutureApi = useListFutureQuery();

    const [listDraft, setListDraft] = useState([]);
    const [listActive, setListActive] = useState([]);
    const [listFuture, setListFuture] = useState([]);
    const [listComplete, setListComplete] = useState([]);

    const [filterlistDraft, setFilterlistDraft] = useState([]);
    const [filterlistActive, setFilterlistActive] = useState([]);
    const [filterlistFuture, setFilterlistFuture] = useState([]);

    useEffect(() => {
        if (listDraftApi.status === 'fulfilled') {
            setTab1Count(listDraftApi.data.Data.length)
            setListDraft(listDraftApi.data.Data);

            //ФИЛЬТР
            var mass_1 = [];
            mass_1.push({ id: 0, name: 'Все проекты', count: listDraftApi.data.Data.length });
            listDraftApi.data.Data.map(item => {
                if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                    mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listDraftApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                }
            });
            setFilterlistDraft(mass_1);

            //Вызываем событие применения фильтра
            if (selectedFilterDraft !== null) {
                filterHandlerDraft(selectedFilterDraft)
            }
        }
    }, [listDraftApi.status]);
    useEffect(() => {
        if (listActiveApi.status === 'fulfilled') {
            setTab2Count(listActiveApi.data.Data.length)
            setListActive(listActiveApi.data.Data);
            //ФИЛЬТР
            var mass_1 = [];
            mass_1.push({ id: 0, name: 'Все проекты', count: listActiveApi.data.Data.length });
            listActiveApi.data.Data.map(item => {
                if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                    mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listActiveApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                }
            });
            setFilterlistActive(mass_1);

            //Вызываем событие применения фильтра
            if (selectedFilterActive !== null) {
                filterHandlerActive(selectedFilterActive)
            }
        }
    }, [listActiveApi.status]);
    useEffect(() => {
        if (listFutureApi.status === 'fulfilled') {
            setTab3Count(listFutureApi.data.Data.length)
            setListFuture(listFutureApi.data.Data);
            //ФИЛЬТР
            var mass_1 = [];
            mass_1.push({ id: 0, name: 'Все проекты', count: listFutureApi.data.Data.length });
            listFutureApi.data.Data.map(item => {
                if (mass_1.filter(a => a.id === item.ID_Project).length === 0) {
                    mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: listFutureApi.data.Data.filter(a => a.ID_Project === item.ID_Project).length });
                }
            });
            setFilterlistFuture(mass_1);

            //Вызываем событие применения фильтра
            if (selectedFilterFuture !== null) {
                filterHandlerFuture(selectedFilterFuture)
            }
        }
    }, [listFutureApi.status]);
    useEffect(() => {
        if (listCompleteApi.status === 'fulfilled') {
            if (listCompleteApi.data.Status == 1) {
                setListComplete(listCompleteApi.data.Data);
                setFilterCompleteLoading(false);
            }
            else {
                setFilterCompleteError(listCompleteApi.data.Error)
                setListComplete([]);
                setFilterCompleteLoading(false);
            }
        }
        if (listCompleteApi.status === 'rejected') {
            setFilterCompleteError('Неизвестная ошибка!')
            setListComplete([]);
            setFilterCompleteLoading(false);
        }
    }, [listCompleteApi.status]);

    /*****************************END API СПИСОК ЗАДАЧ***********************/
    /*********************************START ФИЛЬТР***************************/

    const [selectedFilterDraft, setSelectedFilterDraft] = useState(null);
    const [selectedFilterActive, setSelectedFilterActive] = useState(null);
    const [selectedFilterFuture, setSelectedFilterFuture] = useState(null);

    function filterHandlerDraft(id_project) {     
        setSelectedFilterDraft(id_project);
        if (id_project === 0) {
            setListDraft(listDraftApi.data.Data)
        }
        else {
            setListDraft(listDraftApi.data.Data.filter(a => a.ID_Project == id_project))
        }
    }
    function filterHandlerActive(id_project) {        
        setSelectedFilterActive(id_project);
        if (id_project === 0) {
            setListActive(listActiveApi.data.Data)
        }
        else {
            setListActive(listActiveApi.data.Data.filter(a => a.ID_Project == id_project))
        }
    }
    function filterHandlerFuture(id_project) {
        setSelectedFilterFuture(id_project);
        if (id_project === 0) {
            setListFuture(listFutureApi.data.Data)
        }
        else {
            setListFuture(listFutureApi.data.Data.filter(a => a.ID_Project == id_project))
        }
    }
    function filterHandlerCompleteDateStart(e) {
        setFilterCompleteLoading(true);
        setFilterCompleteError(null);
        setFilterCompleteDateStart(e.target.value);
        //sessionStorage.setItem('taskFilterCompleteDateStart', e.target.value);
    }
    function filterHandlerCompleteDateEnd(e) {        
        setFilterCompleteLoading(true);
        setFilterCompleteError(null);
        setFilterCompleteDateEnd(e.target.value);
        //sessionStorage.setItem('taskFilterCompleteDateEnd', e.target.value);
    }
    /**********************************END ФИЛЬТР****************************/


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-card mb-3 card">
                        <div className="card-header-tab card-header-tab-animation card-header">
                            <div className="card-header-title">
                                <UncontrolledButtonDropdown>
                                    <Button onClick={(e) => modalInfoHandler(e)} color="secondary"><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавить задачу</Button>
                                    <DropdownToggle className="dropdown-toggle-split" caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => taskChangeHandler(1)}>Выполнят один из ответственных</DropdownItem>
                                        <DropdownItem onClick={() => taskChangeHandler(2)}>Выполняет каждый ответственный</DropdownItem>
                                        <DropdownItem onClick={() => taskChangeHandler(3)}>Ответственные указаны в чек листе</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>

                                <ModalAddInfo show={modalAddInfoShow} onHide={() => setModalAddInfoShow(false)} />
                                <ModalTask show={modalTaskShow} onHide={() => setModalTaskShow(false)} edit={false} model={addModel} />

                                {logTaskChange !== null && <div className="form_error ml-30">{logTaskChange}</div>}
                                {loadingTaskChange === true && <FontAwesomeIcon className="ml-10" icon={faSpinner} size="2x" />}
                            </div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a
                                        id="tab_1"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab1Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faArchive} className="mr-5" /> Черновики {tab1Count > 0 && <span className="badge badge-pill badge-success">{tab1Count}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_2"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab2Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faPlay} className="mr-5" /> В работе  {tab2Count > 0 && <span className="badge badge-pill badge-success">{tab2Count}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_3"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab3Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faRocket} className="mr-5" /> Будущие {tab3Count > 0 && <span className="badge badge-pill badge-success">{tab3Count}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_4"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab4Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" /> Выполненные
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="mt-15 mb-15">
                                        <FilterProject list={filterlistDraft} clickHandler={filterHandlerDraft} selected={selectedFilterDraft} />
                                    </div>
                                    <div className="">
                                        <List list={listDraft} isLoading={listDraftApi.isLoading} listCompleteApi={listCompleteApi} filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />
                                    </div>
                                </div>
                                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div>
                                        <FilterProject list={filterlistActive} clickHandler={filterHandlerActive} selected={selectedFilterActive} filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />
                                    </div>
                                    <div className="mt-20">
                                        <List list={listActive} isLoading={listActiveApi.isLoading} listCompleteApi={listCompleteApi} filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />
                                    </div>
                                </div>
                                <div className={tab3Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div>
                                        <FilterProject list={filterlistFuture} clickHandler={filterHandlerFuture} selected={selectedFilterFuture} filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />
                                    </div>
                                    <div className="mt-20">
                                        <List list={listFuture} isLoading={listFutureApi.isLoading} listCompleteApi={listCompleteApi} filterCompleteDateStart={filterCompleteDateStart} filterCompleteDateEnd={filterCompleteDateEnd} />
                                    </div>
                                </div>
                                <div className={tab4Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">                                   
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">Дата завершения</label>
                                            <Form.Control type="date" defaultValue={filterCompleteDateStart} onChange={(e) => filterHandlerCompleteDateStart(e)} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">Дата завершения</label>
                                            <Form.Control type="date" defaultValue={filterCompleteDateEnd} onChange={(e) => filterHandlerCompleteDateEnd(e)} />
                                        </div>
                                        <div className="col-md-8">

                                        </div>
                                        {filterCompleteError !== null && <div className="col-md-12 form_error">{filterCompleteError}</div>}
                                        {filterCompleteLoading === true && (
                                            <>
                                                <div className="col-md-4">
                                                    <div className="loader_01"></div>
                                                </div>
                                            </>
                                        )}                                        
                                    </div>
                                    <div className="mt-20">
                                        <List list={listComplete} isLoading={listCompleteApi.isLoading} listCompleteApi={listCompleteApi} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
