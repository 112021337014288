import * as signalR from '@microsoft/signalr';
import { useUserRequestInListQuery, useUserRequestOutListQuery ,useUserListQuery } from '../api/main/company';

const hubConnection = new signalR.HubConnectionBuilder().withUrl('/api_main/ws/company/').build();

export function CompanyHubConnect() {
    
    hubConnection
    .start()
    .then(function () {
        console.log('ws_company_connect: ok')
    })
    .catch(function (err) {
        console.log('ws_company_connect: error')
        return console.error(err.toString());
    });
}


//Событие после добавления заявки
export function CompanyHubAddRequest({id_request}) {   
    hubConnection.invoke("AddRequest",  id_request).catch(function (err) {
        return console.error(err.toString());
    });
}
//Обновит входящие заявки у пользователей 
export function CompanyHubReceiveAddRequest() { 
    const reqListActive = useUserRequestInListQuery({ID_Status: 1});
    const reqListViwed= useUserRequestInListQuery({ID_Status: 2});

    hubConnection.on("receiveAddRequest", function () {  
        reqListActive.refetch()
        reqListViwed.refetch()
    });
}


//Событие при изменении статуса заявки пользователем
export function CompanyHubChangeStatusRequest({id_request}) {   
    hubConnection.invoke("ChangeStatusRequest",  id_request).catch(function (err) {
        return console.error(err.toString());
    });
}
//Обновит заявки и пользователей у админа
export function CompanyHubReceiveChangeStatusRequest() { 
    const reqList = useUserRequestOutListQuery({ID_Status: 1});
    const userListActive = useUserListQuery({id_status:1}); 

    hubConnection.on("receiveChangeStatusRequest", function () {  
        reqList.refetch()
        userListActive.refetch()
    });
}